import React, { useState } from 'react';
import VehicleTable from './VehicleData/VehicleTable';
import { useVehicleData } from '../../../../../Hooks/TT_ELD/Vehicles';
import SearchOptions from '../../../../../Utils/SearchOptions';
import {
  SearchForTruckId,
  SearchForVehicleId,
  SearchForVehicleVin,
  SearchResultForCompany,
} from '../../../../../Utils/SearchResults';
import { Button, Input, Grid, Drawer } from 'antd';
import { useParams } from 'react-router-dom';
import { AutoComplete } from 'antd';
import SearchInput from '../../../../../Utils/SearchInput';
import useRouteQuery from '../../../../../Utils/useRouterQuery';
import { FilterOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

type params = {
  readonly id: any;
};
type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const VehiclesCompany = () => {
  const screens = useBreakpoint();
  const xs = screens.xs;
  const sm = screens.sm;
  const md = screens.md;
  const lg = screens.lg;
  const xl = screens.xl;
  const xxl = screens.xxl;

  const { id } = useParams<params>();

  const [skip, setSkip] = useState<number>(1);
  const [companyId, setCompanyId] = useState<string | number>(id);
  const [truckId, setTruckId] = useState<string | number>('');
  const [vehicleId, setVehicleId] = useState<string | number>('');
  const [vinId, setVinId] = useState<string | number>('');
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [companyUID, setCompanyUID] = useRouteQuery('', 'companyUID', (v) => String(v));
  const [open, setOpen] = useState(false);
  const { data, refetch, isLoading, isFetching }: Data = useVehicleData(
    id,
    companyId,
    vinId,
    truckId,
    vehicleId,
    uid,
    companyUID,
    skip,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };

  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      companyUID: setCompanyUID,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };

  const [optionsTruck, setOptionsTruck] = useState<Array<any>>([]);
  const [optionsVin, setOptionsVin] = useState<Array<any>>([]);
  const [optionsVehicle, setOptionsVehicle] = useState<Array<any>>([]);
  const handleSearchTruck = async (value: string) => {
    if (value) {
      let data = await SearchForTruckId(value, companyId, vinId);
      setOptionsTruck(data);
    } else {
      setOptionsTruck([]);
    }
  };
  const handleSearchVin = async (value: string) => {
    if (value) {
      let data = await SearchForVehicleVin(value, companyId, truckId);
      setOptionsVin(data);
    } else {
      setOptionsVin([]);
    }
  };
  const handleSearchVehicle = async (value: string) => {
    if (value) {
      let data = await SearchForVehicleId(value, companyId);
      setOptionsVehicle(data);
    } else {
      setOptionsVehicle([]);
    }
  };
  const handleChangeTruck: any = (value: any, { valId }: { valId: number | string }) => {
    setTruckId(valId);
    if (valId) {
      setSkip(1);
    }
  };
  const handleChangeVin: any = (value: any, { valId }: { valId: number | string }) => {
    setVinId(valId);
    if (valId) {
      setSkip(1);
    }
  };
  const handleChangeVehicle: any = (value: any, { valId }: { valId: number | string }) => {
    setVehicleId(valId);
    if (valId) {
      setSkip(1);
    }
  };

  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {!id && (
          <SearchOptions
            SearchResult={SearchResultForCompany}
            onSelect={(value: any, { valId }: { valId: number | string }) => {
              setCompanyId(valId);
              if (valId) {
                setSkip(1);
              }
            }}
            placeholder={!xxl ? 'Company' : ' Search by company'}
            value={''}
          />
        )}
        {sm && (
          <AutoComplete
            options={optionsTruck}
            style={{ width: 300, marginRight: '15px' }}
            onSearch={handleSearchTruck}
            onChange={handleChangeTruck}
          >
            <Input.Search
              allowClear
              placeholder={!xxl ? 'Truck' : ' Search by truck'}
              enterButton
            />
          </AutoComplete>
        )}
        {md && (
          <AutoComplete
            options={optionsVin}
            style={{ width: 300, marginRight: '15px' }}
            onSearch={handleSearchVin}
            onChange={handleChangeVin}
          >
            <Input.Search allowClear placeholder={!xxl ? 'VIN' : ' Search by VIN'} enterButton />
          </AutoComplete>
        )}
        {lg && (
          <AutoComplete
            options={optionsVehicle}
            style={{ width: 300, marginRight: '15px' }}
            onSearch={handleSearchVehicle}
            onChange={handleChangeVehicle}
          >
            <Input.Search
              allowClear
              placeholder={!xxl ? 'Vehicle' : ' Search by vehicle'}
              enterButton
            />
          </AutoComplete>
        )}
        {xl && (
          <SearchInput
            value={uid}
            placeholder={!xxl ? 'UID' : ' Search by UID'}
            onDebounceChange={(event: string) => handleChange(event, 'uid')}
          />
        )}
        {xl && (
          <SearchInput
            value={companyUID}
            placeholder={xxl ? 'Search by Company UID' : xl ? 'Company UID' : 'CMP UID'}
            onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
          />
        )}
        <span style={{ display: 'flex', gap: '10px' }}>
          {!xl && (
            <Button size={'large'} onClick={() => setOpen(true)}>
              <FilterOutlined />
            </Button>
          )}
          {!xs && (
            <Button size={'large'} onClick={refetch}>
              Refresh
            </Button>
          )}
        </span>
      </span>
      <>
        {!xl && (
          <Drawer
            title={null}
            closable={false}
            placement="bottom"
            onClose={() => setOpen(false)}
            open={open}
            height={md ? 90 : sm ? 130 : 280}
          >
            <span className="page-filter-search">
              {!sm && (
                <AutoComplete
                  options={optionsTruck}
                  style={{ width: '100%' }}
                  onSearch={handleSearchTruck}
                  onChange={handleChangeTruck}
                >
                  <Input.Search allowClear placeholder="Search by truck" enterButton />
                </AutoComplete>
              )}
              {!md && (
                <AutoComplete
                  options={optionsVin}
                  style={{ width: '100%', marginRight: '15px' }}
                  onSearch={handleSearchVin}
                  onChange={handleChangeVin}
                >
                  <Input.Search allowClear placeholder=" Search by VIN" enterButton />
                </AutoComplete>
              )}
              {!lg && (
                <AutoComplete
                  options={optionsVehicle}
                  style={{ width: '100%', marginRight: '15px' }}
                  onSearch={handleSearchVehicle}
                  onChange={handleChangeVehicle}
                >
                  <Input.Search allowClear placeholder=" Search by vehicle" enterButton />
                </AutoComplete>
              )}
              {!xl && (
                <SearchInput
                  value={uid}
                  placeholder=" Search by UID"
                  onDebounceChange={(event: string) => handleChange(event, 'uid')}
                  searchWidth={'100%'}
                />
              )}
              {!xl && (
                <SearchInput
                  value={companyUID}
                  placeholder="Search by Company UID"
                  onDebounceChange={(event: string) => handleChange(event, 'companyUID')}
                  searchWidth={'100%'}
                />
              )}
            </span>
          </Drawer>
        )}
      </>
      <VehicleTable
        vehicle={data?.data}
        total={data?.count}
        onChange={onChange}
        isLoading={isLoading}
        isFetching={isFetching}
        skip={skip}
      />
    </div>
  );
};

export default VehiclesCompany;
