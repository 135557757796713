import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form as FormAnt,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useVehicleSearch } from '../../../../../../../Hooks/TT_ELD/Logs';
import { US_STATES, useFilterList } from '../../../../../../../Utils/data';
import { vehicles } from '../../../../../../../API/TT_ELD/Users/vehicles';
import { Typography } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const { Title } = Typography;
interface Params {
  [key: string]: string | undefined;
  id: string | undefined;
}

const VehicleIftaReport = ({ companyId }: { companyId: number }) => {
  const [form] = FormAnt.useForm();
  const { id } = useParams<Params>();
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [chooseTrigger, setChooseTrigger] = useState<'csv' | 'pdf'>('pdf');
  const [chooseTriggerBy, setChooseTriggerBy] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(form.getFieldsValue());
  const [date, setDate] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().startOf('month'),
    dayjs().add(1, 'month').startOf('month'),
  ]);

  const year = 2023;
  const { years, quarters, months } = useFilterList(year, true);

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const vehicleData: any = useVehicleSearch('', companyId);

  const onSubmit = async (values: any) => {
    let from: { year: number; month?: number; quarter?: number } = { year: values.year };
    let to: { year: number; month?: number; quarter?: number } = { year: values.year };

    if (values.year === 1 && date.length === 2) {
      from = {
        year: dayjs(date[0]).year(),
        month: dayjs(date[0]).month() + 1,
      };
      to = {
        year: dayjs(date[1]).year(),
        month: dayjs(date[1]).month() + 1,
      };
    } else if (values.quarters === -1) {
      from.month = 1;
      to.month = 12;
    } else {
      if (values.month) {
        from.month = values.month;
        to.month = values.month;
      }
      if (values.quarters !== undefined) {
        from.quarter = values.quarters;
        to.quarter = values.quarters;
      }
    }

    try {
      await vehicles.iftaReportMerge({
        type: chooseTrigger,
        vehicles: values.vehicles ?? [],
        states: values.states ?? [],
        from,
        to,
        vehicleId: Number(id),
        companyId: companyId,
        all_states: chooseTriggerBy,
      });
      // if (!response || !response.fileName) return;

      // if (response && response.fileName) {
      //   let fileName = response.fileName
      //     ? response.fileName.split('filename=')[1].replaceAll('"', '')
      //     : '';

      //   const fileType = chooseTrigger === 'pdf' ? 'application/pdf' : 'text/csv;charset=utf-8';
      //   const blob = new Blob([response.file], { type: fileType });

      //   downloadFile(blob, fileName);
      // }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setFormValues(allValues);
  };

  React.useEffect(() => {
    form.setFieldsValue({
      year: 2024,
    });
  }, []);

  return (
    <Card
      title="
Generate Report"
    >
      <FormAnt
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ modifier: 'public' }}
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            onChange={(e) => setChooseTrigger(e.target.value)}
            value={chooseTrigger}
          >
            <Title level={5}>Generate as</Title>
            <Radio value="csv">CSV</Radio>
            <Radio value="pdf">PDF</Radio>
          </Radio.Group>
          <Divider />
          {/* <Radio.Group
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            onChange={(e) => setChooseTriggerBy(e.target.value)}
            value={chooseTriggerBy}
          >
            <Title style={{ marginTop: 0 }} level={5}>
              Get by
            </Title>
            <Radio value={false}>States and trucks</Radio>
            <Radio value={true}>States only</Radio>
          </Radio.Group> */}
          <Row style={{ marginTop: '24px' }} gutter={16}>
            <Col span={8}>
              <FormAnt.Item name="states" label="States">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  options={US_STATES.map((option: any, index) => ({
                    key: option.id || index,
                    value: option.value,
                    label: option.text,
                  }))}
                />
              </FormAnt.Item>
            </Col>

            {chooseTriggerBy === false && (
              <Col span={8}>
                <FormAnt.Item
                  name="vehicles"
                  label="Vehicles"
                  rules={[{ required: true, message: 'Please enter your vehicles!' }]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    filterOption={(input, option: any) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={vehicleData?.data?.data?.map((option: any, index: any) => ({
                      key: option.id || index,
                      value: option.id,
                      label: option.truck_number,
                    }))}
                  />
                </FormAnt.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <FormAnt.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: 'Please enter your year!' }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  options={years.map((option: any, index) => ({
                    key: option.id || index,
                    value: option.value,
                    label: option.text,
                  }))}
                />
              </FormAnt.Item>
            </Col>
            {form.getFieldsValue()?.year && form.getFieldsValue()?.year !== 1 && (
              <Col span={8}>
                <FormAnt.Item
                  name="quarters"
                  label="Quarter"
                  rules={[{ required: true, message: 'Please enter your quarter!' }]}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select quarters"
                    options={quarters.map((option: any, index) => ({
                      key: option.id || index,
                      value: option.value,
                      label: option.text,
                    }))}
                  />
                </FormAnt.Item>
              </Col>
            )}
            {form.getFieldsValue()?.quarters === 0 && form.getFieldsValue()?.year !== 1 && (
              <Col span={8}>
                <FormAnt.Item
                  name="month"
                  label="Date range"
                  rules={[{ required: true, message: 'Please enter your month!' }]}
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={months.map((option: any, index) => ({
                      key: option.id || index,
                      value: option.value,
                      label: option.text,
                    }))}
                  />
                </FormAnt.Item>
              </Col>
            )}
            {form.getFieldsValue()?.year === 1 && (
              <Col span={8}>
                <FormAnt.Item name="months" label="Date range" initialValue={date}>
                  <RangePicker
                    picker="month"
                    onChange={(value) => {
                      if (value) {
                        setDate(value as [dayjs.Dayjs, dayjs.Dayjs]);
                      }
                    }}
                  />
                </FormAnt.Item>
              </Col>
            )}
          </Row>
          <FormAnt.Item>
            <Button
              onClick={() => enterLoading(1)}
              loading={loadings[1]}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </FormAnt.Item>
        </Space>
      </FormAnt>
    </Card>
  );
};

export default VehicleIftaReport;
