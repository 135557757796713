import instance from '../../api';
import { message } from 'antd';
import { ErrorNotification } from '../../../Utils/data';

export const deviceVersion = {
  async read() {
    try {
      const response = await instance(`users/logout/users/device-version/list`);
      if (response.data) {
        return {
          data: response.data.data,
          count: response.data.count,
        };
      } else {
        return {
          data: [],
          count: 0,
        };
      }
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async deviceVersionPost(limit: any) {
    const key = 'updatable';
    try {
      if (limit) {
        message.loading({ content: 'Loading...', key });
        const { data }: { data: any } = await instance(
          `users/logout/users/device-version?versioncode=${limit?.versioncode}`,
          {
            method: 'POST',
          },
        );
        message.success({ content: 'Loaded!', key, duration: 2 });
        return data;
      }
    } catch (error: any) {
      console.error('An error occurred:', error);
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
      throw error;
    }
  },

  async deviceCancel(versionCode: string | number) {
    try {
      const { data }: any = await instance(
        `users/logout/users/device-version/cancel?versioncode=${versionCode}`,
        {
          method: 'POST',
        },
      );
      return data?.data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
      throw error;
    }
  },
};
