import instance from '../api';

export const dailies = {
  async read({
    filter,
    countFilter,
    date,
  }: {
    filter: object | string;
    countFilter: object;
    date: string | undefined;
  }) {
    const { data }: { data: object } = await instance(`dailies?filter=${JSON.stringify(filter)}`);
    const count = await instance(`dailies/count?where=${JSON.stringify({ ...countFilter, date })}`);
    return { data, count: count.data.count };
  },
  async companyFinder({ name, id }: { name: string | undefined; id: number | string | undefined }) {
    const { data }: { data: Array<any> } = await instance(
      `companies?filter=${encodeURIComponent(
        JSON.stringify({ where: { name: { ilike: name } } }),
      )}`,
    );

    return data;
  },
  async usdotFinder(name: string) {
    const { data }: any = await instance(`companies/list?searchUsdot=${name}`);
    return data?.data;
  },
  async driverFinder({
    name,
    companyId,
    role,
  }: {
    name: string;
    companyId: number | undefined;
    role: string | undefined;
  }) {
    let filter: any = {
      where: {
        and: [],
      },
      include: 'company',
      order: 'id DESC',
      skip: 0,
      limit: 100,
    };
    if (name && name !== '') {
      let search = name;
      let txtArr = search.split(' ');
      let or: any = [];
      txtArr?.forEach((txt: any) => {
        if (txt !== '') {
          or.push({ first_name: { ilike: `${txt}%` } });
          or.push({ second_name: { ilike: `${txt}%` } });
        }
      });
      if (or && or.length > 0) {
        filter.where.and.push({ or });
      }
    }

    const { data }: { data: Array<any> } = await instance(
      `users?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    );
    return data;
  },
  async driverFinderEmail({
    name,
    companyId,
    role,
  }: {
    name: string;
    companyId: number | undefined;
    role: string | undefined;
  }) {
    let filter: any = {
      where: {
        and: [],
      },
      include: 'company',
      order: 'id DESC',
      skip: 0,
      limit: 100,
    };

    if (name && name !== '') {
      let search = name;
      let txtArr = search.split(' ');
      let or: any = [];
      txtArr?.forEach((txt: any) => {
        if (txt !== '') {
          or.push({ email: { ilike: `%${txt}%` } });
        }
      });
      if (or && or.length > 0) {
        filter.where.and.push({ or });
      }
    }
    const { data }: { data: Array<any> } = await instance(
      `users?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    );
    return data;
  },
  async driverFinderLogs(name: string) {
    const { data }: any = await instance(`users/searching-list?&searchName=${name}&driver=true`);
    return data?.data;
  },

  async driverFinderForTMS(companyId: string | number, driverId: string | number) {
    if (companyId) {
      const { data }: any = await instance(
        `users/searching-list?perPage=10000&companyId=${companyId}&searchName=${driverId}&role=driver`,
      );
      return data?.data;
    }
  },

  async driverFinderUser(name: string, id: number | string) {
    if (id === undefined) id = '';
    const { data }: any = await instance(
      `userCompanies/users?companyId=${id}&searchTypes=${JSON.stringify(['name'])}&search=${name}`,
    );
    return data?.data;
  },
  async driverFinderEmails(name: string, id: string | number) {
    if (id === undefined) id = '';
    const { data }: any = await instance(
      `userCompanies/users?companyId=${id}&searchTypes=${JSON.stringify(['email'])}&search=${name}`,
    );
    return data?.data;
  },
  async driverCompanyFinder(name: undefined | number | string | any) {
    if (typeof name === 'string') {
      name = name.trim();
    }
    const { data }: any = await instance(
      `userCompanies/companies?search=${encodeURIComponent(name)}`,
    );
    return data?.data;
  },
  async searchCompanyUSDOT(usdot: undefined | number | string | any) {
    if (typeof usdot === 'string') {
      usdot = usdot.trim();
    }
    const { data }: any = await instance(`companies/list?searchUsdot=${encodeURIComponent(usdot)}`);
    return data?.data;
  },
  async dispatchersFinderOwner(name: string) {
    const { data }: any = await instance(
      `users/searching-list?page=1&perPage=10&role=dispatcher&searchName=${name}&status=true`,
    );
    return data?.data;
  },
};
