import instance from '../api';
import { message } from 'antd';
import { ErrorNotification } from '../../Utils/data';

export const approval = {
  async read(
    userName: string,
    userEmail: string,
    company: string | number,
    skip: string | number = 1,
  ) {
    const { data }: any = await instance(
      `users/registered-users?page=${skip}&perPage=10&searchName=${userName}&searchEmail=${userEmail}&searchCompany=${encodeURIComponent(
        company,
      )}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },
  async rejectedList(
    userName: string,
    userEmail: string,
    company: string | number,
    skip: string | number = 1,
  ) {
    if (userName || userEmail || company) {
      skip = 1;
    }
    const { data }: any = await instance(
      `users/registered-users?registerStatus=rejected&page=${skip}&perPage=10&searchName=${userName}&searchEmail=${userEmail}&searchCompany=${company}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },
  async approvalPatch(id: any, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });

    try {
      const { data }: { data: any } = await instance(`users/register-edit/${id}`, {
        method: 'PATCH',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      ErrorNotification({
        status: error.response.status,
        message: error.response.data.error.message,
      });
    }
  },
  async approvalCount() {
    const isAuthenticated = localStorage.getItem('token') as string;
    if (isAuthenticated) {
      const { data }: any = await instance(`users/register-count`);
      return {
        data: data.data,
        count: data.count,
      };
    }
  },
};
