import instance from '../../api';
import { message } from 'antd';
import { ErrorNotification } from '../../../Utils/data';
import { notification } from 'antd';
export const companies = {
  async read(
    companyId: number | string,
    usdotId: string | number,
    sort: boolean | undefined,
    skip: number | undefined,
    isDisabled: string,
    ownerId: string,
    isDispute: string,
    uid: string,
  ) {
    if (usdotId === undefined) usdotId = '';
    if (ownerId === undefined) ownerId = '';
    if (typeof companyId === 'string') {
      companyId = companyId.trim();
    }
    let url = isDisabled
      ? `companies/list?page=${skip}&perPage=10&searchName=${encodeURIComponent(
          companyId,
        )}&searchUsdot=${usdotId}&isDisable=true&ownerId=${ownerId}&uid=${uid}`
      : `companies/list?page=${skip}&perPage=10&searchName=${encodeURIComponent(
          companyId,
        )}&searchUsdot=${usdotId}&isDispute=${isDispute}&uid=${uid}`;
    const { data }: any = await instance(url);

    return {
      data: data.data,
      count: data.count,
    };
  },
  async expires(
    companName: number | string,
    usdot: string,
    status: string,
    uid: string,
    skip: number | undefined,
  ) {
    try {
      if (typeof companName === 'string') {
        companName = companName.trim();
      }
      let url = `/companies/near-subscription-charge?page=${skip}&perPage=10&searchName=${companName}&searchUsdot=${usdot}&uid=${uid}&isActive=${status}`;
      const { data }: any = await instance(url);
      return {
        data: data?.companyInfo,
        count: data.count,
      };
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async companyNewsubscription(
    companName: number | string,
    usdot: string,
    status: string,
    uid: string,
    skip: number | undefined,
  ) {
    try {
      if (typeof companName === 'string') {
        companName = companName.trim();
      }
      let url = `/companies/new-subscription?page=${skip}&perPage=10&searchName=${companName}&searchUsdot=${usdot}&uid=${uid}&status=${status}`;
      const { data }: any = await instance(url);
      return {
        data: data?.companyInfo,
        count: data.count,
      };
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async companyData(companyId: string | number | undefined) {
    if (companyId) {
      // await new Promise(resolve => setTimeout(resolve, 1000 + Math.random() * 1000));
      const { data }: { data: any } = await instance(`companies/find/${companyId}`);
      return data;
    }
  },
  async companyPatchData(companyData: any, id: number | string) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data }: { data: any } = await instance(`companies/edit-company/${id}`, {
        method: 'PATCH',
        data: companyData,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async companyPatchWhichservice(companyId: any) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data }: { data: any } = await instance(`companies/change-whichservice/${companyId}`, {
        method: 'PATCH',
        data: companyId,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async stripeMoves(body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`stripes/stripe-moves`, {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async vehicleData(vehicleId: string | number | undefined) {
    if (vehicleId) {
      const { data }: { data: any } = await instance(`vehicles/${vehicleId}`);
      return data;
    }
  },
  async searchVehicleData(truck_number: string | number, companyId: string | number | undefined) {
    if (companyId) {
      const { data }: any = await instance(
        `vehicles/searching-list?perPage=10000&companyId=${companyId}`,
      );
      return {
        data: data.data,
        count: data.count,
      };
    }
  },
  async subscription(stripeId: string, companyId: string) {
    try {
      if (stripeId) {
        const { data }: { data: any } = await instance(
          `stripes/admin/retrieve-subscription-information?subscriptionId=${stripeId}&companyId=${companyId}`,
        );
        return { data: data };
      }
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async updateSubscriptionData(body: number | string | object) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data }: { data: any } = await instance(`stripes/admin/update-subsciption`, {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async getPrice(companyId: string | number | undefined) {
    if (companyId) {
      // await new Promise(resolve => setTimeout(resolve, 1000 + Math.random() * 1000));
      const { data }: { data: any } = await instance(
        `stripes/admin/prices/?companyId=${companyId}`,
      );
      return data;
    }
  },
  async getHistorySubscription(status: string, next: object | any, companyId: string) {
    try {
      const { data }: { data: any } = await instance(
        `stripes/admin/getinvoiceview/?status=${status}&limit=10&companyId=${companyId}&next=${next?.value}&lastInvoiceId=${next.lastInvoiceId}`,
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async checkInvoices(getCompanyId: string | number | undefined) {
    if (getCompanyId) {
      try {
        const { data }: { data: any } = await instance(
          `/stripes/check-invoices?companyId=${getCompanyId}`,
        );
        notification.info({
          message: 'Check Invoices!',
          description: data.message,
          placement: 'topRight',
        });
        return data;
      } catch (error: any) {
        if (error.response && error.response.status) {
          ErrorNotification({
            status: error.response.status,
            message: error.response.data.error.message,
          });
        }
      }
    }
  },
  async regenerate(body: number | string | object) {
    try {
      const key = 'updatable';
      message.loading({ content: 'Loading...', key });
      const { data }: { data: any } = await instance(`iftareports/regenerate-by-log`, {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async userMe(companyId: string | number | undefined) {
    if (companyId) {
      // await new Promise(resolve => setTimeout(resolve, 1000 + Math.random() * 1000));
      const { data }: { data: any } = await instance(`users/me`);
      return data;
    }
  },
};
