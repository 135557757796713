import React from 'react';
import { Modal, Spin, Table, Button } from 'antd';
import moment from 'moment';

type numStr = string | number;

export type FmcsaReportResponse = {
  id: number;
  name: string;
  history: History[];
  is_active: boolean;
  stripe_type: string;
  subscription_quantity: string;
  key: number;
};

export type History = {
  date: Date;
  status: string;
  editedById: number;
  editedBy: EditedBy;
};

export type EditedBy = {
  first_name: string;
  second_name: string;
  id: number;
};

export type Name = {
  name: string;
  id: number;
};

interface reportSource {
  no: numStr;
  editedBy: string;
  text: numStr;
  type: numStr;
  address: numStr;
  status: numStr;
  odometr: numStr;
  date: string;
  key: React.Key;
}

const columns: object[] = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    sorter: {
      compare: (a: any, b: any) => a.no - b.no,
      multiple: 1,
    },
    width: 50,
  },
  {
    title: 'Edited by',
    dataIndex: 'editedBy',
    key: 'editedBy',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date: string) => <div>{moment(date).format('YYYY-MM-DD')}</div>,
  },
];
const SubscriptionHistory = ({
  data,
  isModalVisible,
  setIsModalVisible,
  text,
}: {
  data: FmcsaReportResponse;
  isModalVisible: boolean | undefined;
  setIsModalVisible(isModalVisible: boolean): void;
  text: string;
}) => {
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <Modal
      width="90%"
      title="FMCSA audit"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <>
          <Button onClick={handleCancel}>Cancel</Button>
        </>,
      ]}
    >
      <Spin size="large" spinning={!data}>
        <Table
          bordered
          dataSource={data?.history?.map((u: any, i: number): reportSource => {
            const obj: reportSource = {
              no: i + 1,
              editedBy: u?.editedBy?.first_name + ' ' + u?.editedBy?.second_name,
              text: u.text,
              type: u?.type,
              address: u?.log?.address,
              status: u?.status,
              odometr: u?.log?.odometr,
              date: u.date,
              key: i,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ pageSize: 50, showSizeChanger: false }}
          scroll={{ y: 550, x: 1000 }}
          sticky
        />
      </Spin>
    </Modal>
  );
};

export default SubscriptionHistory;
