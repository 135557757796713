import instance from '../../api';
import { message, notification } from 'antd';

export const serverConfig = {
  async read() {
    const { data }: { data: object } = await instance(`users/config-redis`);
    const getCount = async () => {
      return 0;
    };
    const count = await getCount();
    return { data, count: count };
  },
  async serverConfigPatch(companyData: any) {
    const { id, ...rest } = companyData;
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    const { data }: { data: any } = await instance(`users/config-redis`, {
      method: 'PATCH',
      data: rest,
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },
  async limitPatch(limit: any) {
    const key = 'updatable';
    try {
      if (limit) {
        message.loading({ content: 'Loading...', key });
        const { data }: { data: any } = await instance(`stripes/multiple-stripe-moves`, {
          method: 'POST',
          data: limit,
          timeout: 900000,
        });
        message.success({ content: 'Loaded!', key, duration: 2 });
        return data;
      }
    } catch (error: any) {
      console.error('An error occurred:', error);
      if (error.response.data.error.statusCode === 400) {
        notification.error({
          message: 'Error 400',
          description: error.response.data.error.message,
          style: { backgroundColor: '#FFF2F0', border: '1px solid #ffccc7' },
          duration: 3,
        });
      }
      if (error.response.data.error.statusCode === 500) {
        notification.error({
          message: 'Error 500',
          description: error.response.data.error.message,
          style: { backgroundColor: '#FFF2F0', border: '1px solid #ffccc7' },
          duration: 3,
        });
      }
      throw error;
    }
  },
  async getsubscription() {
    const { data }: { data: object } = await instance(`stripes/multiple-stripe-moves`);
    const getCount = async () => {
      return 0;
    };
    const count = await getCount();
    return { data, count: count };
  },
};
