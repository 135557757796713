import React, { useState } from 'react';
import { Button, Drawer, Grid } from 'antd';
import useRouteQuery from '../../../../../../Utils/useRouterQuery';
import { useCompaniesData } from '../../../../../../Hooks/TT_ELD/Companies';
import SearchInput from '../../../../../../Utils/SearchInput';
import SearchOptions from '../../../../../../Utils/SearchOptions';
import { SearchForOwnerDispatchers } from '../../../../../../Utils/SearchResults';
import CompaniesTable from '../CompaniesTable';
import { FilterOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

const Companies = () => {
  const screens = useBreakpoint();
  const xs = screens.xs;
  const sm = screens.sm;
  const md = screens.md;
  const xl = screens.xl;

  const [companyId, setCompanyId] = useRouteQuery('', 'companyId', (v) => String(v));
  const [usdotId, setUsdotId] = useRouteQuery('', 'usdotId', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [ownerId, setOwnerId] = useState('');
  const [open, setOpen] = useState(false);

  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      companyId: setCompanyId,
      usdotId: setUsdotId,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };
  const [skip, setSkip] = useState<number>(1);
  const { data, refetch, isLoading, isFetching }: Data = useCompaniesData(
    companyId,
    usdotId,
    undefined,
    skip,
    'true',
    ownerId,
    '',
    uid,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <SearchInput
          value={companyId}
          placeholder="Search by company"
          onDebounceChange={(event: string) => handleChange(event, 'companyId')}
        />
        {xl && (
          <SearchInput
            value={usdotId}
            placeholder="Search by USDOT"
            onDebounceChange={(event: string) => handleChange(event, 'usdotId')}
          />
        )}
        {md && (
          <SearchOptions
            SearchResult={SearchForOwnerDispatchers}
            onSelect={(value: any, { valId }: { valId: any }) => {
              setOwnerId(valId);
              if (valId) setSkip(1);
            }}
            placeholder="Search by owner"
            value={''}
          />
        )}
        {xl && (
          <SearchInput
            value={uid}
            placeholder="Search by UID"
            onDebounceChange={(event: string) => handleChange(event, 'uid')}
          />
        )}
        <span style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
          {!xl && (
            <Button size={'large'} onClick={() => setOpen(true)}>
              <FilterOutlined />
            </Button>
          )}
          {!xs && (
            <Button size={'large'} onClick={refetch}>
              Refresh
            </Button>
          )}
        </span>
      </span>

      <>
        {!xl && (
          <Drawer
            title={null}
            closable={false}
            placement="bottom"
            onClose={() => setOpen(false)}
            open={open}
            height={xl ? 100 : md ? 100 : sm ? 130 : 190}
          >
            <span className="page-filter-search">
              <SearchInput
                value={usdotId}
                placeholder="Search by USDOT"
                onDebounceChange={(event: string) => handleChange(event, 'usdotId')}
                searchWidth="100%"
              />
              {!md && (
                <SearchOptions
                  SearchResult={SearchForOwnerDispatchers}
                  onSelect={(value: any, { valId }: { valId: any }) => {
                    setOwnerId(valId);
                    if (valId) setSkip(1);
                  }}
                  placeholder="Search by owner"
                  value={''}
                  searchWidth="100%"
                />
              )}
              <SearchInput
                value={uid}
                placeholder="Search by UID"
                onDebounceChange={(event: string) => handleChange(event, 'uid')}
                searchWidth="100%"
              />
            </span>
          </Drawer>
        )}
      </>

      <CompaniesTable
        companiesData={data?.data}
        total={data?.count}
        onChange={onChange}
        isLoading={isLoading}
        isFetching={isFetching}
        skip={skip}
        refetch={refetch}
      />
    </div>
  );
};

export default Companies;
