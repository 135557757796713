import instance from '../api';
import { message } from 'antd';
import { ErrorNotification } from '../../Utils/data';

export const logbook = {
  async read(company: string | number | undefined, active: string, skip: string | number = 1) {
    const { data }: any = await instance(
      `logbooks/list?page=${skip}&perPage=10&search=${company}&isActive=${active}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },
  async assignLogBook(name: string | number | undefined) {
    const { data }: any = await instance(`logbooks/list?page=1&perPage=10&search=${name}&all=true`);
    return {
      data: data.data,
      count: data.count,
    };
  },
  async logbookFind(id: string | number | undefined) {
    try {
      const { data }: { data: any } = await instance(`logbooks/find/${id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async logbookPatch(id: string | number | undefined, lobData: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    try {
      const { data }: { data: any } = await instance(`logbooks/${id}`, {
        method: 'PATCH',
        data: lobData,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });

      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async addLogbook(body: number | string) {
    message.loading({ content: 'Loading...', key: body });
    try {
      const { data } = await instance('logbooks/create', {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: body, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
  async assignLogbook(body: any) {
    // message.loading( {content: 'Loading...', key: body});
    try {
      const { data } = await instance(`logbooks/assign-user/${body.logbookId}/${body.userId}`, {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          // message.success({content: 'Loaded!', key: body, duration: 2});
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },
};
