import { useQuery } from 'react-query';
import { companies } from '../../../API/TT_ELD/Users/company';

export const useCompaniesData = (
  companyId: number | string,
  usdotId: string | number,
  sort: boolean | undefined,
  skip: number | undefined,
  isDisabled: string,
  ownerId: string,
  isDispute: string,
  uid: string,
): object => {
  return useQuery(
    [
      `companies/${companyId || 'all'}`,
      companyId,
      usdotId,
      sort,
      skip,
      isDisabled,
      ownerId,
      isDispute,
      uid,
    ],
    () => companies.read(companyId, usdotId, sort, skip, isDisabled, ownerId, isDispute, uid),
    { refetchOnWindowFocus: false },
  );
};
export const useExpires = (
  companName: number | string,
  usdot: string,
  status: string,
  uid: string,
  skip: number | undefined,
): object => {
  return useQuery(
    [`companies/expires/${companName || 'all'}`, companName, usdot, status, uid, skip],
    () => companies.expires(companName, usdot, status, uid, skip),
    { refetchOnWindowFocus: false },
  );
};

export const useCompanyNewsubscription = (
  companName: number | string,
  usdot: string,
  status: string,
  uid: string,
  skip: number | undefined,
): object => {
  return useQuery(
    [
      `companies/companyNewsubscription/${companName || 'all'}`,
      companName,
      usdot,
      status,
      uid,
      skip,
    ],
    () => companies.companyNewsubscription(companName, usdot, status, uid, skip),
    { refetchOnWindowFocus: false },
  );
};
export const useCompanyData = (companyId: number | string | undefined): any => {
  return useQuery([`companies/${companyId}`, companyId], () => companies.companyData(companyId), {
    refetchOnWindowFocus: false,
  });
};
export const useGetPrice = (companyId: number | string | undefined): any => {
  return useQuery([`getPrice/${companyId}`, companyId], () => companies.getPrice(companyId), {
    refetchOnWindowFocus: false,
  });
};
export const useVehicleData = (vehicleId: number | string | undefined): any => {
  return useQuery([`vehicles/${vehicleId}`, vehicleId], () => companies.vehicleData(vehicleId), {
    refetchOnWindowFocus: false,
  });
};

export const useVehicleSearch = async (
  truck_number: string | number,
  companyId: string | number | undefined,
) => {
  return await companies.searchVehicleData(truck_number, companyId);
};

export const useSubscription = (stripeId: string, companyId: string): any => {
  return useQuery(
    [`subscription/${stripeId}`, stripeId, companyId],
    () => companies.subscription(stripeId, companyId),
    { refetchOnWindowFocus: false },
  );
};
export const useGetHistorySubscription = (status: string, next: object, companyId: string): any => {
  return useQuery(
    [`getHistorySubscription/`, status, next, companyId],
    () => companies.getHistorySubscription(status, next, companyId),
    { refetchOnWindowFocus: false },
  );
};
export const useCheckInvoices = (getCompanyId: string | number | undefined): object => {
  return useQuery([`checkStripe/`, getCompanyId], () => companies.checkInvoices(getCompanyId), {
    refetchOnWindowFocus: false,
  });
};
export const useUsersMe = (companyId: number | string | undefined): any => {
  return useQuery([`userMe/${companyId}`, companyId], () => companies.userMe(companyId), {
    refetchOnWindowFocus: false,
  });
};
