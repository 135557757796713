import React, { useEffect, useState } from 'react';
import { Button, Spin, Table, Tag } from 'antd';
import useRouteQuery from '../../../../../../Utils/useRouterQuery';
import { useCompanyNewsubscription } from '../../../../../../Hooks/TT_ELD/Companies';
import SearchInput from '../../../../../../Utils/SearchInput';
import { useLocation } from 'react-router-dom';
import { calcOrder } from '../../../../../components/core/calcOrder';
import SubscriptionHistory from './SubscriptionHistory';

export type FmcsaReportResponse = {
  id: number;
  name: string;
  history: History[];
  is_active: boolean;
  stripe_type: string;
  subscription_quantity: string;
  key: number;
};

export type History = {
  date: Date;
  status: string;
  editedById: number;
  editedBy: EditedBy;
};

export type EditedBy = {
  first_name: string;
  second_name: string;
  id: number;
};

type Data = {
  data?: {
    data: Array<any>;
    count: number;
  };
  isLoading?: boolean;
  refetch?: any;
  isFetching?: any;
};

type numStr = string | number;
interface reportSource {
  id: numStr;
  name: { name: string; id: string };
  history: Array<any>;
  companyId: numStr;
  stripe_type: string;
  created: string;
  is_active: boolean;
  subscription_quantity: string;
  key: React.Key;
}

const RecentSubscription = () => {
  const location = useLocation();
  const [name, setName] = useRouteQuery('', 'name', (v) => String(v));
  const [usdot, setUsdot] = useRouteQuery('', 'usdot', (v) => String(v));
  const [uid, setUid] = useRouteQuery('', 'uid', (v) => String(v));
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [status, setStatus] = useRouteQuery('', 'status', (v) => String(v));
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [getOneData, setGetOneData] = useState<FmcsaReportResponse>();
  const handleCancel = (record: any) => {
    setGetOneData(record);
  };

  const handleChange = (event: string, inputName: string) => {
    const updateFunctions: Record<string, (value: string) => void> = {
      name: setName,
      usdot: setUsdot,
      uid: setUid,
    };

    if (updateFunctions[inputName]) {
      updateFunctions[inputName](event.trim());
      setSkip(1);
    }
  };
  const [skip, setSkip] = useState<number>(1);
  const { data, refetch, isLoading, isFetching }: Data = useCompanyNewsubscription(
    name,
    usdot,
    status,
    uid,
    skip,
  );
  const onChange = (query: any) => {
    setSkip(1 * parseInt(query.current));
  };
  useEffect(() => {
    if (sortedInfo?.order) {
      setStatus(sortedInfo?.order);
      setSkip(1);
    }
  }, [sortedInfo, location.pathname]);

  const columns: object[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Company',
      dataIndex: 'name',
      key: 'name',
      render: ({ name, id }: { name: string; id: string }) => <>{name}</>,
    },

    {
      title: 'Stripe Type',
      dataIndex: 'stripe_type',
      key: 'stripe_type',
    },
    {
      title: 'Subscription quantity',
      dataIndex: 'subscription_quantity',
      key: 'subscription_quantity',
      width: 220,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (tag: boolean) => (
        <Tag color={tag ? 'geekblue' : 'red'}>{tag ? 'True' : 'False'}</Tag>
      ),
      sorter: (a: any, b: any) => a.is_active?.length - b?.is_active?.length,
      sortOrder: sortedInfo.columnKey === 'is_active' && sortedInfo.order,
      sortDirections: ['trialing', 'active', ' '],
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: (created: string) => {
        const formattedDate = new Date(Number(created) * 1000).toISOString().split('T')[0];

        return <div>{formattedDate}</div>;
      },
    },
    {
      title: 'History',
      dataIndex: 'history',
      key: 'history',
      width: 220,
      render: () => {
        return (
          <>
            <Button onClick={() => setIsModalVisible(true)}>View</Button>
          </>
        );
      },
    },
  ];

  const handleChangeIsActive = (query: any, filters: any, sorter: any) => {
    onChange(query);
    const { order, field } = sorter;
    setSortedInfo({ columnKey: field, order });
  };
  return (
    <div className="mainPage">
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {' '}
        <SearchInput
          value={name}
          placeholder="Search by name"
          onDebounceChange={(event: string) => handleChange(event, 'name')}
        />
        <SearchInput
          value={usdot}
          placeholder="Search by USDOT"
          onDebounceChange={(event: string) => handleChange(event, 'usdot')}
        />
        <SearchInput
          value={uid}
          placeholder="Search by UID"
          onDebounceChange={(event: string) => handleChange(event, 'uid')}
        />
        <Button style={{ marginLeft: 'auto' }} size={'large'} onClick={refetch}>
          Refresh
        </Button>
      </span>

      <Spin size="large" spinning={isLoading || isFetching}>
        <SubscriptionHistory
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={getOneData as unknown as FmcsaReportResponse}
          text={'Account Approval Information'}
        />
        <Table
          onRow={(record, id) => {
            return {
              onClick: () => {
                handleCancel(record);
              },
            };
          }}
          style={{ margin: '15px 0 0 0' }}
          onChange={handleChangeIsActive}
          dataSource={data?.data?.map((u: any, i: number): reportSource => {
            const obj: reportSource = {
              id: calcOrder(skip, 10, i),
              name: { name: u?.name, id: u.id },
              history: u.history,
              companyId: u?.companyId,
              created: u?.created,
              is_active: u.is_active,
              stripe_type: u?.stripe_type,
              subscription_quantity: u.subscription_quantity,
              key: i,
            };
            return obj;
          })}
          columns={columns}
          pagination={{ total: data?.count, showSizeChanger: false }}
        />
      </Spin>
    </div>
  );
};

export default RecentSubscription;
