import instance from '../../api';
import { message } from 'antd';
import { ErrorNotification } from '../../../Utils/data';

export const makesList = {
  async read(makeName: string | number | undefined, skip: string | number = 1) {
    if (makeName) {
      skip = 1;
    }
    const { data }: any = await instance(
      `makes/list?page=${skip}&perPage=10&isActive=true&relationModels=true&search=${makeName}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },

  async makeFindOne(Id: string | number | undefined) {
    try {
      const { data }: { data: any } = await instance(`makes/${Id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async makePatch(id: string | undefined, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });

    try {
      const { data }: { data: any } = await instance(`makes/${id}`, {
        method: 'PATCH',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async addMakesList(body: number | string) {
    message.loading({ content: 'Loading...', key: body });
    try {
      const { data } = await instance('makes/create', {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: body, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async makeSelect() {
    const { data }: any = await instance(
      `makes/list?perPage=1000&isActive=true&relationModels=true`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },
};

export const modelList = {
  async read(
    modelName: string | number | undefined,
    makeId: string | number | undefined,
    skip: string | number = 1,
  ) {
    if (makeId === undefined) {
      makeId = '';
    }
    const { data }: any = await instance(
      `models/list?page=${skip}&perPage=10&isActive=true&search=${modelName}&makeId=${makeId}`,
    );
    return {
      data: data.data,
      count: data.count,
    };
  },

  async modelFindOne(Id: string | number | undefined) {
    try {
      const { data }: { data: any } = await instance(`models/${Id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async modelPatch(id: string | number, body: any) {
    const key = 'updatable';
    message.loading({ content: 'Loading...', key });
    const { data }: { data: any } = await instance(`models/${id}`, {
      method: 'PATCH',
      data: body,
    }).then((u) => {
      setTimeout(() => {
        message.success({ content: 'Loaded!', key, duration: 2 });
      }, 1000);
      return u;
    });
    return data;
  },

  async addModelList(body: number | string) {
    message.loading({ content: 'Loading...', key: body });
    try {
      const { data } = await instance('models/create', {
        method: 'POST',
        data: body,
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Loaded!', key: body, duration: 2 });
        }, 1000);
        return u;
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.status) {
        ErrorNotification({
          status: error.response.status,
          message: error.response.data.error.message,
        });
      }
    }
  },

  async deleteModelList(id: number | string) {
    message.loading({ content: 'Loading...', key: id });
    let res;
    let error = '';
    try {
      const { data } = await instance(`models/${id}`, {
        method: 'DELETE',
      }).then((u) => {
        setTimeout(() => {
          message.success({ content: 'Deleted!', key: id, duration: 2 });
        }, 1000);
        return u;
      });
      res = data;
    } catch (err) {
      error = 'Oops something went wrong!';
    }
    return { data: res, error };
  },

  async modelSelect(makeId: string | number | undefined) {
    if (makeId) {
      const { data }: any = await instance(`models/list?perPage=1000&makeId=${makeId}`);
      return data?.data?.map((model: any) => ({
        value: model?.id,
        label: model?.name,
      }));
    }
  },

  async modelsFinder(modelName: undefined | number | string) {
    const { data }: any = await instance(`models/list?isActive=true&search=${modelName}`);
    return data?.data;
  },
};
